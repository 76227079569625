define("discourse/plugins/discourse-topic-alarm/discourse/components/topic-alarm-info", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@glimmer/tracking", "discourse/lib/bookmark", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _service, _tracking, _bookmark, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasTopicAlarm}}
    <div class="topic-alarm {{this.alarmClass}}">
      <div class="topic-alarm-time">
        {{d-icon "discourse-bookmark-clock"}}
        {{this.existingAlarmAtFormatted}}
      </div>
      <div class="topic-alarm-description">
        {{this.topicAlarmDescription}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "oLvPnVnZ",
    "block": "[[[41,[30,0,[\"hasTopicAlarm\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"topic-alarm \",[30,0,[\"alarmClass\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"topic-alarm-time\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"discourse-bookmark-clock\"],null]],[1,\"\\n      \"],[1,[30,0,[\"existingAlarmAtFormatted\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"topic-alarm-description\"],[12],[1,\"\\n      \"],[1,[30,0,[\"topicAlarmDescription\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-topic-alarm/discourse/components/topic-alarm-info.hbs",
    "isStrictMode": false
  });
  class TopicAlarmInfo extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "userTimezone", [_tracking.tracked]))();
    #userTimezone = (() => (dt7948.i(this, "userTimezone"), void 0))();
    constructor() {
      super(...arguments);
      this.userTimezone = this.currentUser.user_option.timezone;
      this.subscribe();
    }
    willDestroy() {
      this.unsubscribe();
    }
    subscribe() {
      if (this.currentUser && this.currentUser.can_set_topic_alarm) {
        const channel = `/topic-alarm/`;
        this.messageBus.subscribe(channel, this._processMessage);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "subscribe", [_decorators.bind]))();
    unsubscribe() {
      if (this.currentUser && this.currentUser.can_set_topic_alarm) {
        const channel = `/topic-alarm/`;
        this.messageBus.unsubscribe(channel, this._processMessage);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "unsubscribe", [_decorators.bind]))();
    _processMessage(data) {
      if (data.topic_id == this.args.topic.get("id")) {
        this.args.topic.set("topic_alarm_time", data.topic_alarm_time);
        this.args.topic.set("topic_alarm_user_time", data.topic_alarm_user_time);
        this.args.topic.set("topic_alarm_description", data.topic_alarm_description);
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "_processMessage", [_decorators.bind]))();
    get hasTopicAlarm() {
      return this.args.topic.get("topic_alarm_user_time") > 0;
    }
    get hasActiveTopicAlarm() {
      return this.args.topic.get("topic_alarm_time") > 0;
    }
    get alarmClass() {
      return this.hasActiveTopicAlarm ? "topic-alarm-active" : "topic-alarm-expired";
    }
    get topicAlarmDescription() {
      return this.args.topic.get("topic_alarm_description");
    }
    get existingAlarmAtFormatted() {
      return (0, _bookmark.formattedReminderTime)(this.args.topic.get("topic_alarm_user_time") * 1000, this.userTimezone);
    }
  }
  _exports.default = TopicAlarmInfo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopicAlarmInfo);
});